import { useDefaultRequest } from "./request";

export const useApiHomeBannerList = (params: any): any => {
  return useDefaultRequest.post("/swgw/switcherOfficial/banner/page", params);
};

export const useApiCaseList = (params: any): any => {
  return useDefaultRequest.post("/swgw/switcherOfficial/article/page", params);
};

//根据模块名称查询seo配置
export const getSeoData = (params: any): any => {
  return useDefaultRequest.get(`/swgw/switcherOfficial/seo/module/${params}`);
};

// 查询seo列表
export const getSeoList = (params: any): any => {
  return useDefaultRequest.post("/swgw/switcherOfficial/seo/list", params);
};

export const articleView = (params: any): any => {
  return useDefaultRequest.get(`/swgw/switcherOfficial/article/view/${params}`);
};

export const articleDetail = (params: any): any => {
  return useDefaultRequest.get(`/swgw/switcherOfficial/article/${params}`);
};
